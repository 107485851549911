@keyframes pathTriangle {
    33% {
        stroke-dashoffset: 74;
    }
    66% {
        stroke-dashoffset: 147;
    }
    100% {
        stroke-dashoffset: 221;
    }
}

@keyframes dotTriangle {
    0% {
        transform: translate(0, 0);
    }
    33% {
        transform: translate(160%, 330%);
    }
    66% {
        transform: translate(330%, 0%);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes pathRect {
    25% {
        stroke-dashoffset: 64;
    }
    50% {
        stroke-dashoffset: 128;
    }
    75% {
        stroke-dashoffset: 192;
    }
    100% {
        stroke-dashoffset: 256;
    }
}

@keyframes dotRect {
    25% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(18px, -18px);
    }
    75% {
        transform: translate(0, -36px);
    }
    100% {
        transform: translate(-18px, -18px);
    }
}

@keyframes pathCircle {
    25% {
        stroke-dashoffset: 125;
    }
    50% {
        stroke-dashoffset: 175;
    }
    75% {
        stroke-dashoffset: 225;
    }
    100% {
        stroke-dashoffset: 275;
    }
}

@keyframes loading_screen_animation_move {
    0% {
        opacity: 0;
        transform: translate3d(0, 50%, 0);
    }
    18%,
    2% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        opacity: 0;
    }
    22%,
    38% {
        opacity: 1;
        transform: translate3d(0, -150%, 0);
    }
    42%,
    58% {
        opacity: 1;
        transform: translate3d(0, -300%, 0);
    }
    62%,
    78% {
        opacity: 1;
        transform: translate3d(0, -450%, 0);
    }
    82%,
    98% {
        opacity: 1;
        transform: translate3d(0, -600%, 0);
    }
    100% {
        opacity: 0;
        transform: translate3d(0, -600%, 0);
    }
}

.triangle-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 48px;
    height: 48px;
    position: relative;
}

.loader:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 9999px;
    position: absolute;
    display: block;
    background-color: #2d5b5b;
    top: 40%;
    left: 20%;
    animation: dotTriangle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg {
    display: block;
    width: 100%;
    height: 100%;
}

.loader svg polygon {
    fill: none;
    stroke: currentColor;
    stroke-width: 8px;
    stroke-linejoin: round;
    stroke-linecap: round;
}

.loader svg polygon {
    stroke-dasharray: 145 76 145 76;
    stroke-dashoffset: 0;
    animation: pathTriangle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.logo {
    position: absolute;
    bottom: 2rem;
    width: 12rem;
    margin-left: auto;
    margin-right: auto;
}

.loading-phrases-container {
    height: 18px;
    margin-top: 18px;
    overflow-y: hidden;
}

.loading-phrases {
    animation: loading_screen_animation_move 25s infinite;
    animation-delay: 5s;
    animation-fill-mode: backwards;
    width: 100%;
    text-align: center;
    height: 18px;
    overflow-y: visible;
}

.loading-phrases > p {
    height: 18px;
    margin-bottom: 9px;
    font-size: 13px;
}
